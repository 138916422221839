import FileUploader from "./components/FileUploader";
import "./index.css";

function App() {
  return (
    <>
      <FileUploader />
    </>
  );
}

export default App;
