import React, { useState } from "react";
import { saveAs } from "file-saver";

function FileUploader() {
  const [file, setFile] = useState(null);

  const onChangeHandler = (e) => {
    setFile(e.target.files[0]);
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (file === null) {
      return;
    }

    const formData = new FormData();
    formData.append("audio", file);

    let fileName = file.name.split('.').slice(0, -1).join('.') + '.data'

    fetch("https://encoderapi.scorteanucosmin.dev/api/convert/fromaudio?api-version=1.0", {
      method: "POST",
      body: formData,
    })
        .then(function (response) {
          return response.blob();
        })
        .then(function (blob) {
          saveAs(blob, fileName);
        })
        .catch((err) => {
          console.log(err);
        });

    e.target.reset();
    setFile(null);
  };

  return (
    <>
      <div className="backdrop-blur-sm flex justify-center">
        <form onSubmit={onSubmitHandler}>
          <h1 className="font-medium ">Upload your audio file</h1>
          <input type="file" accept=".mp3, .wav, .json" onChange={onChangeHandler} />
          <button className="btn btn-primary">Submit</button>
        </form>
      </div>
    </>
  );
}

export default FileUploader;
